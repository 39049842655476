#CybotCookiebotDialog h2 {
    transform: rotate(0) !important;
}

.header__standort {
    display: flex;
    flex-direction: column;
    height: 90px;
    margin-right: 4px;

    .standort__label {
        display: block;
        padding: 4px 12px;
        flex: 1 1 auto;
        font-size: pxToRem(13);
        color: $font-color-light;
        line-height: 18px;
        text-transform: initial;
        background-color: $color-secondary;
        box-shadow: 0 0 12px 0 rgba($color-black, .25);
    }

    .select__wrapper {
        position: relative;
        flex: 1 1 75%;
        margin-top: 4px;

        &::after {
            content: '';
            background-image: inline-svg('angle-s', $color-white, transparent);
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translateY(-50%) rotate(90deg);
            pointer-events: none;
        }
    }

    .standort__auswahl {
        appearance: none;
        height: 100%;
        padding: 16px 57px 16px 12px;
        color: $font-color-light;
        background-color: $color-secondary;
        box-shadow: 0 0 12px 0 rgba($color-black, .25);
        cursor: pointer;
    }
}

.header__gutscheine--mobile {
    display: none;
}

.js-nav-scrolled02 {
    .header__container--buttons {
        margin-top: 10px;
    }

    .header__ctas {
        height: 100%;
    }
}

.footer__awards--left {
    flex-wrap: wrap;
    .award {
        max-width: 120px;
    }

    .award__2 {
        max-width: 107px;
    }

    .award__3 {
        max-width: 107px;
    }

    a:not(:last-child) {
        margin-right: 30px;
    }
}

@include media-breakpoint-down(xl) {
    .js-nav-scrolled02 .header__container--buttons {
        align-items: initial;
    }

    .header__gutscheine {
        display: none !important;
    }

    .header__gutscheine--mobile {
        display: block;
        position: absolute;
        top: 10px;
        right: 90px;
    }
}

@include media-breakpoint-down(sm) {
    .header__gutscheine--mobile {
        top: 2px;
        right: 80px;
    }

    .header__standort .standort__auswahl {
        padding-right: 35px;
        font-size: pxToRem(16);
    }

    .footer__awards--left {
        flex-direction: column;

        a:not(:last-child) {
            margin-right: initial;
            margin-bottom: 16px;
        }
    }
}