.intermediate {
    padding-bottom: 35%;
    overflow: hidden;
    position: relative;
    margin-bottom: $margin-bottom;
    & > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

    }
}

.intermediate + footer {
    margin-top: -100px;
}

@include media-breakpoint-down(sm) {
    .intermediate {
        padding-bottom: 80%;
        margin-bottom: $spacing-xl;
    }

    .intermediate + footer {
        margin-top: -64px;
    }

    .teaser-slider + .intermediate {
        margin-top: -64px;
    }
}