.event-teaser__item--container {
    display: flex;
    padding: $spacing-xl;
    margin: $spacing-xl 0;

    &:nth-child(2n) {
        flex-direction: row-reverse;

        .event-teaser__image {
            margin-right: initial;
            margin-left: $spacing-l;
        }
    }
}

.event-teaser__image {
    width: 100%;
    margin-right: $spacing-l;
    img {
        position: relative;
    }
}

.event-teaser__body {
    width: 100%;
    color: $font-color;
}

.event-teaser__hl {
    display: inline-block;
    margin-bottom: $spacing-m;
}

.event-teaser__eventdate {
    padding-bottom: 8px;
    border-bottom: 1px dashed $color-black;

    span {
        padding: 6px 8px 6px 12px;
        color: $font-color-light;
        background-color: $color-black;
    }
}

.event-teaser__content {
    margin-top: 16px;
}

@include media-breakpoint-down(md) {
    .event-teaser__item--container {
        flex-direction: column;
        padding: 12px;

        &:nth-child(2n) {
            flex-direction: column;

            .event-teaser__image {
                margin-left: initial;
            }
        }
    }

    .event-teaser__image {
        margin-bottom: $spacing-s;
    }
}