@import "../icon/sass-inline-svg";

/**
 * Default
 */

[data-page] [data-id]:not(.visible) {
    display: none;
}

.teaser__more {
    display: none;
}

.teaser, .list {
    position: relative;
    //padding: $spacing-xl 0;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-orange {
    background-color: $orange;
}

.bg-blue {
    background-color: $blue;
}

.bg-purple {
    background-color: $purple;
}

.teaser__item--container, .list__item--container {
    margin: 64px 0;
}

.teaser__item, .list__item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 8px;
    color: $font-color;
    border-bottom: 1px dashed $color-black;
    //margin: 32px 0;

    .button {
        margin-top: 35px;
    }

    &:hover {
        .teaser__image img, .list__image img {
            transform: scale(1.03);
        }

        .teaser__image .swiper2, .list__image .swiper2 {
            .swiper-button-prev, .swiper-button-next {
                opacity: 1;
            }
        }

        .teaser__price, .list__price {
            transform: rotate(-10deg);
        }

        .teaser__hl, .list__hl {
            transform: rotate(6deg);
        }

        .teaser__button, .list__button {
            &::after {
                right: 10px !important;
            }
        }
    }
}

.teaser__image, .list__image {
    position: relative;
    margin-bottom: 24px;

    .image {
        overflow: hidden;
    }

    img {
        transition: all .4s ease !important;
    }
}

.teaser__image .swiper2, .list__image .swiper2 {
    .swiper-button-prev, .swiper-button-next {
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 56px;
        height: 56px;
        padding: 0;
        background-color: rgba($color-black, .2);
        border-radius: 50%;
        opacity: 0;
        transition: opacity .4s ease;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: inline-svg('angle-m', $color-white, transparent);
        }
    }

    .swiper-button-prev {
        left: 20px;

        &::after {
            transform: translate(-50%, -50%) rotate(180deg);
        }
    }

    .swiper-button-next {
        right: 20px;
    }
}


.teaser__body, .list__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .teasertext {
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}

.teaser__hl, .list__hl {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: -5px;
    z-index: 10;
    padding: 0 10px;
    transform: rotate(-6deg);
    font-size: pxToRem(24);
    transition: .4s ease;
}

.price {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .4s ease;
}

.price__container {
    display: inline-flex;
    margin-bottom: 5px;
}

.price__prefix, .price__price, .price__suffix {
    font-family: $font01;
    text-transform: uppercase;
    color: #fff;
    background-color: $col01;
    display: inline;
    padding: 3px 10px 2px 10px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.price__prefix, .price__suffix {
    line-height: pxToRem(16);
    font-size: pxToRem(16);
}

.price__price {
    line-height: pxToRem(32);
    font-size: pxToRem(32);
}


.teaser__price, .list__price {
    position: absolute;
    top: 22px;
    right: 22px;
    z-index: 10;
    transform: rotate(10deg);

    span:not(:last-child) {
        margin-bottom: 5px;

    }

    .price__container {
        flex-direction: column;
        align-items: center;
        -webkit-backface-visibility: hidden;
    }
}

.teaser__swiper {
    padding: $spacing-xl 0;
}

.teaser-big .price, .teaser__swiper--big .price {
    top: 24px;
    right: 24px;
}

.teaser__facts, .list__facts {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    li {
        position: relative;
        line-height: pxToRem(18);
        font-family: $font03;
        font-size: pxToRem(16);
        text-transform: uppercase;
        margin-left: 18px;

        &:not(:last-child) {
            margin-right: 18px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -18px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            background-image: inline-svg('plus-s', $color-black);
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

.teaser__stoerer {

    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    z-index: 10;

}

.view-rooms .filter-background + .teaser {
    padding-top: 64px;
    .teaser__item--container {
        margin: 0 0 64px;
    }
}

.teaser-big .teaser__items {
    padding-top: 64px;
}

.teaser-big .teaser__item--container {
    margin-top: initial;
}

@include media-breakpoint-down(lg) {
    .teaser-big .teaser__item--container:not(:last-child), .teaser-big .list__item--container:not(:last-child) {
        //margin-bottom: 0 !important;
        margin-bottom: 36px !important;
    }
}

@include media-breakpoint-down(md) {
    .teaser.teaser-slider {
        .swiper-button-prev, .swiper-button-next {
            display: none;
        }
    }

    .teaser__item--container:not(:first-child), .list__item--container:not(:first-child), .teaser__item--container:not(:last-child), .list__item--container:not(:last-child) {
        //margin-bottom: 0 !important;
        margin-bottom: 36px !important;
    }
}

@include media-breakpoint-down(sm) {
    .teaser__item--container, .list__item--container {
        //margin: initial;
    }

    .teaser__item--container:not(:first-child), .list__item--container:not(:first-child) {
        //margin: $spacing-m 0 !important;
        margin: 0 0 36px !important;
    }

    .teaser__item--container:last-child, .list__item--container:last-child {
        margin-bottom: 64px !important;
    }

    .teaser__item--container:last-child, .list__item--container:last-child {
        //margin-bottom: 0 !important;
    }

    .teaser.teaser-slider {
        margin: $spacing-xl 0;

        .swiper-button-prev, .swiper-button-next {
            display: none;
        }
    }
}