/**
 * Import media breakpoints and other shraed vars (js/css)
 */
@import 'shared-vars';
@import 'vars-figma-colors';

/**
 * Use autogenerated media
 */
$media-steps: $sx-breakpoints;

$grid: (
    steps: (1, 12),
    gutters: (),
    breakpoint-gutters: (
        'xs': 10px,
        'sm': 10px,
        'md': 28px,
        'lg': 28px,
        'xl': 28px,
        'xxl': 28px,
    ),
    container-paddings: (
        'xs': 20px,
        'sm': 20px,
        'md': 60px,
        'lg': 60px,
        'xl': 60px,
        'xxl': 60px,
    ),
    container-paddings-fluid: (
        'xs': 20px,
        'sm': 20px,
        'md': 60px,
        'lg': 60px,
        'xl': 60px,
        'xxl': 60px,
    )
);

$image-ratio-steps: (
    1, 2, 3, 4, 5, 6, 7, 9
);

$spacing-xxs: pxToRem(8);
$spacing-xs: pxToRem(12);
$spacing-s: pxToRem(14);
$spacing-m: pxToRem(28);
$spacing-l: pxToRem(18);
$spacing-xl: pxToRem(20);
$spacing-xxl: pxToRem(24);

$spacing-desktop-xxs: pxToRem(8);
$spacing-desktop-xs: pxToRem(12);
$spacing-desktop-s: pxToRem(14);
$spacing-desktop-m: pxToRem(28);
$spacing-desktop-l: pxToRem(18);
$spacing-desktop-xl: pxToRem(20);
$spacing-desktop-xxl: pxToRem(24);

$spacing-mobile-xxs: pxToRem(9);
$spacing-mobile-xs: pxToRem(12);
$spacing-mobile-s: pxToRem(14);
$spacing-mobile-m: pxToRem(28);
$spacing-mobile-l: pxToRem(18);
$spacing-mobile-xl: pxToRem(20);
$spacing-mobile-xxl: pxToRem(24);


//$modulspacing-desktop-xxs: pxToRem(9);
//$modulspacing-desktop-xs: pxToRem(12);
$modulspacing-desktop-s: pxToRem(48);
$modulspacing-desktop-m: pxToRem(96);
$modulspacing-desktop-l: pxToRem(144);
//$modulspacing-desktop-xl: pxToRem(20);
//$modulspacing-desktop-xxl: pxToRem(24);

//$modulspacing-mobile-xxs: pxToRem(9);
//$modulspacing-mobile-xs: pxToRem(12);
$modulspacing-mobile-s: pxToRem(28);
$modulspacing-mobile-m: pxToRem(56);
$modulspacing-mobile-l: pxToRem(84);
//$modulspacing-mobile-xl: pxToRem(20);
//$modulspacing-mobile-xxl: pxToRem(24);

/**
 * Border radius vars
 */

$border-radius: 0px !default;
$border-radius-button: 5px;
/* If border-radius-button is not defined somewhere else, use border-radius as default */
$border-radius-button: $border-radius !default;

/**
 * Sx vars
 */

$sx-path-images: '/dist/img/';

$header-height: 80px;

/**
 * Font vars
 */
$sx-path-fonts: '/dist/font/';
$base-font-size: 19px;
//$font01: 'DynamoeOT', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font01: 'OnlyDynamoe', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';


$font02: 'KingthingsTrypewriterPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font03: 'Plankentype V3', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$margin-bottom: 100px;

/**
 * Spacing Vars
 */

$spacing-xl:64px;
$spacing-l: 48px;
$spacing-m: 36px;
$spacing-s: 28px;

/**
 * Color vars
 */

$font-color: #000000;
$font-color-light: #FFFFFF;

$color-primary: #E50E2B;
//$color-primary-hover: darken($color-primary, 5%) !default;
$color-primary-hover: #D40924;

$color-secondary: #404040;
$color-secondary-dark: #333333;
$color-secondary-hover: $color-secondary-dark;

$col01: $color-primary;
$col02: $color-secondary;

$yellow: #F1EFDF;
$orange: #EDDDCB;
$blue: #E7EFF3;
$purple: #E0D9E3;

$color-background: #E5E5E5;
$color-footer: #1A1A1A;

$color-success: #3ed786;
$color-warning: $col01;
$color-danger: #fd2f51;
$color-info: #37C4EF;

$color-white: #FFFFFF;
$color-black: #000000;

//die Farben müssen soviel Kontrast haben, dass sie als BG und Fontcolor funktionieren
$color-light: #F9FCFF;
$color-dark: #4B525B;

$color-neutral-light: $color-white;
$color-neutral-dark: $color-black;

$color-gray-05: mix($color-black, $color-white, 0.05 * 100%);
$color-gray-10: mix($color-black, $color-white, 0.10 * 100%);
$color-gray-15: mix($color-black, $color-white, 0.15 * 100%);
$color-gray-20: mix($color-black, $color-white, 0.20 * 100%);
$color-gray-25: mix($color-black, $color-white, 0.25 * 100%);
$color-gray-30: mix($color-black, $color-white, 0.30 * 100%);
$color-gray-35: mix($color-black, $color-white, 0.35 * 100%);
$color-gray-40: mix($color-black, $color-white, 0.40 * 100%);
$color-gray-45: mix($color-black, $color-white, 0.45 * 100%);
$color-gray-50: mix($color-black, $color-white, 0.50 * 100%);
$color-gray-55: mix($color-black, $color-white, 0.55 * 100%);
$color-gray-60: mix($color-black, $color-white, 0.60 * 100%);
$color-gray-65: mix($color-black, $color-white, 0.65 * 100%);
$color-gray-70: mix($color-black, $color-white, 0.70 * 100%);
$color-gray-75: mix($color-black, $color-white, 0.75 * 100%);
$color-gray-80: mix($color-black, $color-white, 0.80 * 100%);
$color-gray-85: mix($color-black, $color-white, 0.85 * 100%);
$color-gray-90: mix($color-black, $color-white, 0.90 * 100%);
$color-gray-95: mix($color-black, $color-white, 0.95 * 100%);


$color-white-light: lighten($color-white, 5%) !default;
$color-white-lighter: lighten($color-white, 10%) !default;

$color-white-dark: darken($color-white, 5%) !default;
$color-white-darker: darken($color-white, 10%) !default;


$color-black-light: lighten($color-black, 5%) !default;
$color-black-lighter: lighten($color-black, 10%) !default;

$color-black-dark: darken($color-black, 5%) !default;
$color-black-darker: darken($color-black, 10%) !default;

$color-light-light: lighten($color-white, 5%) !default;
$color-light-lighter: lighten($color-white, 10%) !default;

$color-light-dark: darken($color-white, 5%) !default;
$color-light-darker: darken($color-white, 10%) !default;


$color-dark-light: lighten($color-white, 5%) !default;
$color-dark-lighter: lighten($color-white, 10%) !default;

$color-dark-dark: darken($color-white, 5%) !default;
$color-dark-darker: darken($color-white, 10%) !default;


$color-primary-light: lighten($color-primary, 5%) !default;
$color-primary-lighter: lighten($color-primary, 10%) !default;

$color-primary-dark: darken($color-primary, 5%) !default;
$color-primary-darker: darken($color-primary, 10%) !default;


$color-secondary-light: lighten($color-secondary, 5%) !default;
$color-secondary-lighter: lighten($color-secondary, 10%) !default;

$color-secondary-dark: darken($color-secondary, 5%) !default;
$color-secondary-darker: darken($color-secondary, 10%) !default;


$color-success-light: lighten($color-success, 5%) !default;
$color-success-lighter: lighten($color-success, 10%) !default;

$color-success-dark: darken($color-success, 5%) !default;
$color-success-darker: darken($color-success, 10%) !default;


$color-warning-light: lighten($color-warning, 5%) !default;
$color-warning-lighter: lighten($color-warning, 10%) !default;

$color-warning-dark: darken($color-warning, 5%) !default;
$color-warning-darker: darken($color-warning, 10%) !default;


$color-danger-light: lighten($color-danger, 5%) !default;
$color-danger-lighter: lighten($color-danger, 10%) !default;

$color-danger-dark: darken($color-danger, 5%) !default;
$color-danger-darker: darken($color-danger, 10%) !default;


$color-info-light: lighten($color-info, 5%) !default;
$color-info-lighter: lighten($color-info, 10%) !default;

$color-info-dark: darken($color-info, 5%) !default;
$color-info-darker: darken($color-info, 10%) !default;


$color-background-light: lighten($color-background, 10%) !default;
$color-background-lighter: lighten($color-background, 20%) !default;

$color-background-dark: darken($color-background, 10%) !default;
$color-background-darker: darken($color-background, 20%) !default;
