.container-gallery {

}

.grid-gallery {
    margin-left: -46px;
    margin-right: -46px;
    display: flex;
    flex-wrap: wrap;
}

.col-gallery-item {
    width: 50%;
    padding-left: 46px;
    padding-right: 46px;

    .gallery-item__image--container {
        display: flex;
        flex-direction: column;
        margin-bottom: 92px;
    }
}

.gallery__item--image {
    position: relative;

    .image {
        overflow: hidden;
    }

    img {
        //position: relative;
        transform: scale(1.00);
        //transition: opacity 3s, transform .4s ease;
    }
}

.gallery__item--image {
    display: block;
    position: relative;
}

.gallery .gallery__item--image {
    .image::after {
        content: '';
        background-image: inline-svg('magnifier', $color-white, transparent);
        background-size: 64px 64px;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: rgba($color-black, .2);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0;
        transition: opacity .4s ease;
    }
    &:hover .image::after {
        opacity: 1;
    }
}

.gallery__item--image:hover::before, .gallery__item--image:hover .image::after {
    opacity: 1;
}

.gallery__item--image:hover img {
    transform: scale(1.04);
    transition: transform .4s ease, opacity 1s !important;
}
.gallery__item--video, .gallery__item--image {
    .image img {
        position: relative;
    }

    [class*=ratio] img {
        //position: absolute !important;
    }
}
.gallery .image {
    background: transparent;
}

/* || Galerie-Slider */

.gallery-slider {
    position: relative;
    margin: 128px 0;
    overflow: hidden;

    .container {
        position: initial;
    }

    .swiper {
        overflow: visible !important;
    }

    .swiper-button-prev {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .swiper-button-next {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .swiper-slide {
        transition: transform 1s ease;
    }

    .swiper-slide:not(.swiper-slide-active) {
        transform: scale(.9);
    }

    .gallery__item--image {
        &::after {
            content: '';
            background-image: inline-svg('magnifier', $color-white, transparent);
            background-size: 64px 64px;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: rgba($color-black, .2);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            transition: opacity .4s ease;
        }
        &:hover:after {
            opacity: 1;
        }
    }
}

@include media-breakpoint-down(md) {
    .grid-gallery {
        margin: 0;
    }

    .col-gallery-item {
        width: 100%;
        padding: 0;

        .gallery-item__image--container {
            margin-bottom: $spacing-xl;
        }
    }

    .gallery-slider {
        margin: 96px 0;
    }
}