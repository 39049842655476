.filter-background {
    background-color: $color-secondary;
    background-image: url("/assets/img/bg-scratched.png");
}

.filter {
    display: flex;
    padding: $spacing-m 0;
}

.formular-item {
    &:nth-child(odd) .filter__label {
        transform: rotate(-3deg);
    }

    &:nth-child(even) .filter__label {
        transform: rotate(3deg);
    }

    &:not(:first-child) {
        margin-left: $spacing-xl;
    }
}

.filter__label {
    display: inline-block;
    font-family: $font03;
    font-size: pxToRem(26);
    text-transform: uppercase;
    color: $font-color-light;
}

.filter-select {
    position: relative;

    &.open .filter-select__list {
        display: block;
    }

    &.open .filter-select__label::after {

    }
}

.filter-select__label {
    height: 50px;
    color: $font-color-light !important;
    background-image: none !important;
    background-color: $color-secondary !important;
    border: 1px solid $color-black !important;

    &:hover {
        cursor: pointer;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 5px;
        width: 40px;
        height: 40px;
        background-color: $color-white;
        background-image: inline-svg('angle-s', $color-black, transparent);
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-50%) rotate(90deg);
    }
}

.filter-select__list {
    position: absolute;
    display: none;
    width: 100%;
    z-index: 99;
    margin-top: 12px;
    box-shadow: 0px 0px 12px rgba($color-black, .2);

    ul li {
        display: flex;
        align-items: center;
        padding: 12px 12px 15px;
        color: $font-color-light;
        background-color: $color-secondary;

        &:first-child {
            padding-top: 24px;
        }

        &:last-child {
            padding-bottom: 24px;
        }

        &:not(:last-child) {
            margin-bottom: 2px;
        }

        &:hover {
            background-color: $color-secondary-hover;
        }

        input[type='checkbox'] {
            appearance: none;
            display: grid;
            place-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $color-white;
            cursor: pointer;

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                background-image: inline-svg('check', $color-black);
                background-size: 16px 16px;
                background-repeat: no-repeat;
                opacity: 0;
            }

            &:checked::before {
                opacity: 1;
            }
        }

        label {
            flex: 1;
            margin-left: 12px;
            cursor: pointer;
        }
    }
}

.filter__field {
    margin-top: $spacing-s;
}

.js-items__empty {
    padding-bottom: $spacing-xl;
    text-align: center;
    font-size: pxToRem(22);
}

@include media-breakpoint-down(lg) {
    .filter {
        flex-direction: column;

        .formular-item:not(:first-child) {
            margin-left: initial;
            margin-top: $spacing-m;
        }
    }


}