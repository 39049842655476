.formular {
    margin-bottom: 128px;
}

.formular-donut {
    display: none;
}

.formular .grid > [class*=col], .formular .grid > [class^=col] {
    margin-bottom: 20px;
}

.formular.formular--horizontal .formular-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.formular.formular--horizontal .formular-item > * {
    flex: 1 1 auto;
}

.formular.formular--horizontal .formular-item__label {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 4px;
}

@include media('sm') {
    .formular.formular--horizontal .formular-item__label {
        width: 160px;
    }
}

.formular.formular--vertical .formular-item__label {
    margin-bottom: 4px;
}


.formular.formular--horizontal .fomular-item__error {
    flex: 0 0 auto;
    width: 100%;
}

.formular-item {
    margin-bottom: 0;
}

.formular-item__label > span:not(:last-child),
.custom-control-label > span:not(:last-child),
{
    margin-right: 4px;
}

.formular-item__checkbox .formular-item__required {
    margin-left:4px;
}

.formular-item__body {
    display: flex;
    flex-direction: column;
}

.formular .formular-item__label {
    display: flex;
    align-items: center;
}

.formular-item__required, .form-error label, {
    color: $color-danger;
}


.formular-item__tooltip {
    font-size: $base-font-size*0.8;
    color: $color-info;
    width: $base-font-size * 0.8;
    display: inline-block;
}

.formular-item__infotext {
    margin-top: 5px;
    font-size: $base-font-size*0.8;
    color: $color-gray-70;
}

.fomular-item__error > .error-message {
    margin-top: 3px;
    display: block;
    color: $color-danger;
    font-size: $base-font-size*0.8;
}

.formular-item__checkbox--single > .checkbox > label,
.formular-item__radio--single > .radio > label {
    min-height: 34px;
}

.formular .formular-item__input {
    padding: 0 14px;
    font-size: pxToRem(16);
    color: $font-color;
    background-color: $color-white;
    border: 2px solid $color-black;
    transition: border .25s ease;

    &:hover, &:focus {
        border: 2px solid $color-primary;
    }
}

.formular input.formular-item__input {
    height: 50px;
}

.formular textarea.formular-item__input {
    padding: 14px;
}

.formular .chosen-single {
    border: 2px solid $color-black !important;

    &:hover, &:focus {
        border: 2px solid $color-primary !important;
    }
}

.formular h4 {
    margin-top: 10px;
}

.formular .checkbox > label input[type="checkbox"] {
    border: 2px solid $color-black;

    &:hover, &:focus {
        border: 2px solid $color-primary;
    }
}

.room__booking + .formular {
    margin-top: 64px;
}


