.popup {
    z-index: 9999999;
    position: relative;
}

.popup__frame {
    position: fixed;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 200;
    margin-left: 5px;
    margin-bottom: 5px;
    pointer-events: none;
    display: none;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 1s ease;
}

.popup h2 {
    font-size: 26px;
    text-align: left;
    margin-bottom: 20px;
}

.popup p:last-child {
    margin-bottom: 0;
}

.popup__body {
    width: 360px;
    height: auto;
    background: #fff;
    padding: 30px 30px 70px;
    line-height: 1.4;
    font-size: 17px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    position: relative;
    opacity: 0;
    transform: translateY(0px);
    transition: all 1s ease;
}

.popup__close {
    cursor: pointer;
    position: absolute;
    top: -18px;
    right: -18px;
    display: block;
    width: 36px;
    height: 36px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 500px;
    transform: rotate(-135deg);
    transition: all .6s ease;
}

.popup__close:hover {
    transform: rotate(45deg);
}

.popup__close:before {
    content: '\00a0';
    position: absolute;
    left: 10px;
    top: calc(50% - 1px);
    display: block;
    width: 15px;
    height: 1px;
    background: #333;
}

.popup__close:after {
    content: '\00a0';
    position: absolute;
    left: calc(50% - 1px);
    top: 10px;
    display: block;
    width: 1px;
    height: 15px;
    background: #333;
}

.popup__cta a {
    //display: block;
    //background: #378bce;
    //color: #fff !important;
    //padding: 10px 20px;
    //text-align: center;
    //border-radius: 5px;
    //background: #378bce;
    //background: -moz-linear-gradient(top, #378bce 0%, #236fac 100%);
    //background: -webkit-linear-gradient(top, #378bce 0%, #236fac 100%);
    //background: linear-gradient(to bottom, #378bce 0%, #236fac 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#378bce', endColorstr='#236fac', GradientType=0);
    position: relative;
    height: 50px;
    padding: 12px 44px 12px 12px;
    text-decoration: none;
    background-color: #E50E2B;
    color: #fff;
    font-size: 1.31579rem;
    line-height: 1;
    margin-right: 28px;
    text-transform: uppercase;
    border: none;
    transition: 0.15s background, 0.15s border, 0.15s color;
}

.popup__cta a::after {
    content: '';
    position: absolute;
    right: 8px;
    display: block;
    width: 12px;
    height: 12px;
    background-image: inline-svg('angle-s', $color-white, transparent);
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: .4s ease;
}

.popup.popup-open .popup__frame {
    display: flex;
    opacity: 1;
    transform: translateX(0);
}

.popup.popup-open .popup__body {
    pointer-events: auto;
    opacity: 1;
    transform: none;
    transition: opacity 400ms 200ms ease, transform 400ms 200ms ease;
}