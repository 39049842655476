.swiper-wrapper {
    width: auto;
    min-width: 100%
}

.grid .swiper-slide {
    height: auto;
}

/* for fullwidth swiper*/
.swiper, .swiper-fullwidth {
    overflow: visible !important;
}


.swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
    display: none;
}

.swiper-button-prev, .swiper-button-next {
    top: 100px;
    width: 40px;
    height: auto;
    padding: 34px 9px;
    background-color: $color-primary;
    transition: width .4s ease;
    &::after {
        content: '';
        width: 22px;
        height: 22px;
        background-image: inline-svg('arrow-right-long-tail', $color-white);
        background-size: 22px 22px;
        background-position: center;
        background-repeat: no-repeat;
    }
    &:hover {
        width: 60px;
    }
}

.swiper-button-next {
    right: 0;
}

.swiper-button-prev {
    left: 60px;
    &::after {
        transform: rotate(180deg);
    }
}

.teaser-slider > .container {
    padding-right: 0 !important;
}

.teaser__item .swiper2, .list__item .swiper2 {
    overflow: hidden;

    .image {
        flex: 0 0 100%;
    }
}

@include media-breakpoint-down(sm) {
    .swiper-button-next, .swiper-button-prev {
        height: 68px;
    }
}