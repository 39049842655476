.teaser-blog__item-container {
    padding: pxToRem(36) pxToRem(20) pxToRem(20);
}

.teaser-blog__eventdate {
    display: inline-block;
    margin-bottom: pxToRem(16);
    transform: rotate(-6deg);
    border-bottom: none;

    time {
        padding: 6px 8px 6px 12px;
        color: $font-color-light;
        background-color: $color-black;
    }
}

.teaser-blog {
    margin: pxToRem(72) 0 pxToRem(96);
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    .headline--headline {
        position: relative;
        padding-bottom: pxToRem(16);
        margin-bottom: pxToRem(16);
        font-family: $font03;
        font-size: 1.36842rem;
        line-height: 140%;
        text-transform: uppercase;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            width: 30px;
            height: 2px;
            background-color: $color-black;
        }
    }
}

.teaser-blog__body {
    flex: 1;
}

.teaser-blog__teasertext {
    margin-bottom: pxToRem(28);
}

.teaser-blog__image {
    margin-top: pxToRem(20);
}

.teaser-blog__image img {
    position: relative;
}

.teaser-blog__item-container--mobile {
    display: none;
}

.teaser-blog__content--outer {
    padding-bottom: pxToRem(8);
    border-bottom: 1px dashed $color-black;
}

.teaser-blog__items {
    .teaser-blog__item-container:not(:last-child) {
        margin-bottom: pxToRem(28);
    }

    .column1, .column2 {
        .teaser-blog__item-container {
            margin-right: pxToRem(28);
        }
    }
}

@include media-breakpoint-down(xl) {
    .teaser-blog__item-container--mobile {
        display: flex;
        padding: pxToRem(24) pxToRem(16) pxToRem(16);
    }

    .teaser-blog__item-container {
        display: none;
    }

    .teaser-blog__items {
        .teaser-blog__item-container--mobile:not(:last-child) {
            margin-bottom: pxToRem(20);
        }
    }
}