/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/dist/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/

@font-face {
    //https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
     //avoid showing invisible text
    //font-family: "???";
    //src: url('/dist/font/???");

}


//--------------------
/**
 * @license
 * MyFonts Webfont Build ID 3106560, 2015-10-12T11:16:38-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: KingthingsTrypewriterPro by CheapProFonts
 * URL: http://www.myfonts.com/fonts/cheapprofonts/kingthings-trypewriter-pro/regular/
 * Copyright: Kingthings Trypewriter 2. by Kevin King. 2007. All Rights Reserved
 * Licensed pageviews: Unlimited
 *
 * Webfont: FF Dynamoe Web by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-dynamoe/dynamoe/
 * Copyright: 2009 Just van Rossum published by FSI FontShop International GmbH
 * Licensed pageviews: 500,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3106560
 *
 * © 2015 MyFonts Inc
*/

@font-face {
    //NICHT!
    font-family: 'KingthingsTrypewriterPro';
    font-display: swap;
    src: url('/dist/font/2F6700_0_0.eot');
    src: url('/dist/font/2F6700_0_0.eot?#iefix') format("embedded-opentype"), url('/dist/font/2F6700_0_0.woff2') format("woff2"), url('/dist/font/2F6700_0_0.woff') format("woff"), url('/dist/font/2F6700_0_0.ttf') format("truetype");
}

//@font-face {
//    font-family: 'DynamoeOT';
//    src: url('/dist/font/DynamoeOT.eot');
//    src: url('/dist/font/DynamoeOT.eot?#iefix') format('embedded-opentype'),
//    url('/dist/font/DynamoeOT.woff2') format('woff2'),
//    url('/dist/font/DynamoeOT.woff') format('woff'),
//    url('/dist/font/DynamoeOT.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//onlydynamoe-webfont
@font-face {
    font-family: 'OnlyDynamoe';
    src: url('/dist/font/onlydynamoe-webfont.eot');
    src: url('/dist/font/onlydynamoe-webfont.eot?#iefix') format('embedded-opentype'),
    url('/dist/font/onlydynamoe-webfont.woff2') format('woff2'),
    url('/dist/font/onlydynamoe-webfont.woff') format('woff'),
    url('/dist/font/onlydynamoe-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Plankentype V3';
    src: url('/dist/font/PlankentypeV3Regular.woff2') format('woff2'),
    url('/dist/font/PlankentypeV3Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


