/**
 * Calendar and List
 */

.calendar__group {
    padding: 20px 0;
}

.calendar__group-title {
    text-align: center;

    h2 {
        display: block;
    }

    span {
        display: inline;
        padding: 0 $spacing-m;
        color: $font-color-light;
        background-color: $color-black;
        text-transform: uppercase;
        line-height: 1.4;
    }
}

/**
 * Calendar
 */

.calendar--bg {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: calc(100% - 90px);
        background-color: $color-secondary;
    }
}

.calendar__daterange {
    max-width: 360px;
    margin: 0 auto;
    position: relative;
    padding-top: 30px;

    .daterange__container:not(:first-child) {
        padding-left: 8px;
    }
}

.calendar__daterange-from,
.calendar__daterange-to {
    position: relative;

    input {
        text-align: center;
    }
}

.label__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 10px 0;
    background-color: #262626;
    label {
        display: inline-block;
        padding: 0 15px;
        background: $color-primary;
        color: $font-color-light;
        font-family: $font01;
        text-transform: uppercase;
        transform: rotate(-6deg);
        line-height: 1.2;
    }
}

.calendar__daterange-from > input,
.calendar__daterange-to > input,
.calendar__daterange-submit > button {
    width: 100%;
    height: 150px;
}

.calendar__daterange-submit > button:not(.buttin--link) {
    margin-top: 8px;
    margin-right: initial;
}

/**
 * Event
 */

.event__calendar {
    margin-top: 96px;
}

.calendar__eventdate.is-today span {
    color: $color-primary;
}

.calendar__eventdate.is-current span {
    font-weight: bold;
}

#eventFrom, #eventTo {
    cursor: pointer;
}

.eventRangeFrom--coverup, .eventRangeTo--coverup {
    position: absolute;
    top: 41px;
    left: 1px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2px);
    height: 148px;
    background-color: $color-white;
    pointer-events: none;
}

.eventRangeFrom--day, .eventRangeTo--day {
    font-family: $font03;
    font-size: 48px;
    margin-top: 12px;
}

.eventRangeFrom--monthYear, .eventRangeTo--monthYear {
    padding: 12px;
    font-size: pxToRem(17);
    text-transform: uppercase;
    white-space: nowrap;
}

.eventRangeFrom--monthYear::after, .eventRangeTo--monthYear::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 8px;
    width: calc(100% - 16px);
    height: 1px;
    background-color: #D9D9D9;
}

@include media-breakpoint-down(sm) {
    .calendar__daterange-submit > button:not(.buttin--link) {
        margin-bottom: initial;
    }
}