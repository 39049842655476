$button-font-size: 16px !default;
$button-height: 10px !default;
$button-ratio: 0.385 !default;

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    //white-space: nowrap;
}

.button.button--block {
    width: 100%;
}

.button:not(.button--link) {
    position: relative;
    height: 50px;
    padding: 12px 44px 12px 12px;
    text-decoration: none;
    background-color: $col01;
    color: #fff;
    font-size: pxToRem(25);
    line-height: 1;

    margin-right: 28px;
    text-transform: uppercase;
    border: none;
    transition: 0.15s background, 0.15s border, 0.15s color;

    &::after {
        content: '';
        position: absolute;
        right: 8px;
        display: block;
        width: 12px;
        height: 12px;
        background-image: inline-svg('angle-s', $color-white, transparent);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: .4s ease;
    }

    &:hover {
        background-color: $color-primary-hover;

        &::after {
            right: 15px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .button:not(.button--link) {
        height: initial;
    }
}

.button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}

.button.button--square:not(.button--link) {
    //width: $button-height;
    width: 42px;
    height: 100%;
    padding: 0;
    text-align: center;
    &::after {
        content: none;
    }
}

.button:not(.button--link):hover,
.button:not(.button--link):focus {
    background: $color-primary-hover;
    border-color: $color-primary-hover;
}

.button.button--small:not(.button--link) {
    font-size: pxToRem(18);
    padding: 6px 28px 5px 12px;
    height: 30px;

    &::after {
        right: 5px;
    }

    &:hover::after {
        right: 10px;
    }
}

@include media-breakpoint-down(xl) {
    .button.button--small:not(.button--link) {
        height: initial;
    }
}

.button.button--small.button--square:not(.button--link) {
    width: $button-height * 0.875;
    padding: 0;
}

.button.button--large:not(.button--link) {
    //font-size: $button-font-size * 1.125;
    //padding: 0 $button-height * $button-ratio * 1.125;
    //height: $button-height * 1.125;
    height: 90px;
    padding: 33px 40px 33px 16px;
    font-size: pxToRem(24);

    &::after {
        right: 8px;
        width: 16px;
        height: 16px;
        background-image: inline-svg('angle-m', $color-white, transparent);
        background-size: 16px 16px;
    }

    &:hover::after {
        right: 15px;
    }

}

.button.button--large.button--square:not(.button--link) {
    width: $button-height * 1.125;
    padding: 0;
}

.button.button--black {
    background: #000;
}

.button.button--special:not(.button--link) {
    justify-content: flex-start;
    padding: 11px 40px 11px 16px;
    font-size: pxToRem(20);
    line-height: 28px;
    min-width: 183px;
    color: $font-color;
    background-color: $color-white;

    &::after {
        right: 8px;
        width: 16px;
        height: 16px;
        background-image: inline-svg('angle-m', $font-color, transparent);
        background-size: 16px 16px;
    }

    &:hover::after {
        right: 15px;
    }
}

@include media-breakpoint-down(sm) {
    .button.button--large:not(.button--link) {
        padding: 36px 24px 36px 8px;

        &::after {
            right: 4px;
        }
    }

    .button.button--special:not(.button--link) {
        min-width: initial;
        padding: 36px 24px 36px 8px;
        line-height: pxToRem(18);
        font-size: pxToRem(18);

        &::after {
            right: 4px;
        }
    }
}

/**
 * Link
 */

.button.button--link {
    font-size: $button-font-size;
    color: $color-primary;
    transition: 0.15s color;
}

.button.button--link:hover,
.button.button--link:focus {
    color: $color-primary-hover;
}

.button.button--link.button--primary {
    color: $color-primary;
}

.button.button--link.button--primary:hover,
.button.button--link.button--primary:focus {
    color: $color-primary-hover;
}

.button.button--link.button--secondary {
    color: $color-secondary;
}

.button.button--link.button--secondary:hover,
.button.button--link.button--secondary:focus {
    color: $color-secondary-hover;
}


.button.button--link.button--success {
    color: $color-success;
}

.button.button--link.button--success:hover,
.button.button--link.button--success:focus {
    color: $color-success-darker;
}

.button.button--link.button--warning {
    color: $color-warning;
}

.button.button--link.button--warning:hover,
.button.button--link.button--warning:focus {
    color: $color-warning-darker;
}

.button.button--link.button--danger {
    color: $color-danger;
}

.button.button--link.button--danger:hover,
.button.button--link.button--danger:focus {
    color: $color-danger-darker;
}

.button.button--link.button--info {
    color: $color-info;
}

.button.button--link.button--info:hover,
.button.button--link.button--info:focus {
    color: $color-info-darker;
}

.button.button--small.button--link {
    font-size: $button-font-size * 0.875;
}

.button.button--large.button--link {
    font-size: $button-font-size * 1.125;
}

.button.button--link[disabled],
.button.button--link[disabled]:hover,
.button.button--link[disabled]:focus {
    color: $color-gray-40;
}

/**
 * Button primary
 */

.button.button--primary:not(.button--link) {
    @include dynamic-color($color-primary);
    border-color: $color-primary;
}

.button.button--primary:not(.button--link):hover,
.button.button--primary:not(.button--link):focus {
    @include dynamic-color($color-primary-hover);
    border-color: $color-primary-hover;
}

.button.button--primary:not(.button--link):active {
    @include dynamic-color($color-primary-dark);
    border-color: $color-primary-dark;
}

.button.button--primary:not(.button--link)[disabled],
.button.button--primary:not(.button--link)[disabled]:hover,
.button.button--primary:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-primary);
    border-color: $color-primary;
}

/**
 * Button secondary
 */

.button.button--secondary:not(.button--link) {
    @include dynamic-color($color-secondary);
    border-color: $color-secondary;
}

.button.button--secondary:not(.button--link):hover,
.button.button--secondary:not(.button--link):focus {
    @include dynamic-color($color-secondary-hover);
    border-color: $color-secondary-hover;
}

.button.button--secondary:not(.button--link):active {
    @include dynamic-color($color-secondary-dark);
    border-color: $color-secondary-dark;
}

.button.button--secondary:not(.button--link)[disabled],
.button.button--secondary:not(.button--link)[disabled]:hover,
.button.button--secondary:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-secondary);
    border-color: $color-secondary;
}

/**
 * Button success
 */

.button.button--success:not(.button--link) {
    @include dynamic-color($color-success);
    border-color: $color-success;
}

.button.button--success:not(.button--link):hover,
.button.button--success:not(.button--link):focus {
    @include dynamic-color($color-success-light);
    border-color: $color-success-light;
}

.button.button--success:not(.button--link):active {
    @include dynamic-color($color-success-dark);
    border-color: $color-success-dark;
}

.button.button--success:not(.button--link)[disabled],
.button.button--success:not(.button--link)[disabled]:hover,
.button.button--success:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-success-lighter);
    border-color: $color-success-lighter;
}

/**
 * Button warning
 */

.button.button--warning:not(.button--link) {
    @include dynamic-color($color-warning);
    border-color: $color-warning;
}

.button.button--warning:not(.button--link):hover,
.button.button--warning:not(.button--link):focus {
    @include dynamic-color($color-warning-light);
    border-color: $color-warning-light;
}

.button.button--warning:not(.button--link):active {
    @include dynamic-color($color-warning-dark);
    border-color: $color-warning-dark;
}

.button.button--warning:not(.button--link)[disabled],
.button.button--warning:not(.button--link)[disabled]:hover,
.button.button--warning:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-warning-lighter);
    border-color: $color-warning-lighter;
}

/**
 * Button danger
 */

.button.button--danger:not(.button--link) {
    @include dynamic-color($color-danger);
    border-color: $color-danger;
}

.button.button--danger:not(.button--link):hover,
.button.button--danger:not(.button--link):focus {
    @include dynamic-color($color-danger-light);
    border-color: $color-danger-light;
}

.button.button--danger:not(.button--link):active {
    @include dynamic-color($color-danger-dark);
    border-color: $color-danger-dark;
}

.button.button--danger:not(.button--link)[disabled],
.button.button--danger:not(.button--link)[disabled]:hover,
.button.button--danger:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-danger-lighter);
    border-color: $color-danger-lighter;
}

/**
 * Button info
 */

.button.button--info:not(.button--link) {
    @include dynamic-color($color-info);
    border-color: $color-info;
}

.button.button--info:not(.button--link):hover,
.button.button--info:not(.button--link):focus {
    @include dynamic-color($color-info-light);
    border-color: $color-info-light;
}

.button.button--info:not(.button--link):active {
    @include dynamic-color($color-info-dark);
    border-color: $color-info-dark;
}

.button.button--info:not(.button--link)[disabled],
.button.button--info:not(.button--link)[disabled]:hover,
.button.button--info:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-info-lighter);
    border-color: $color-info-lighter;
}

/**
 * Button group
 */

.button-group {
    display: flex;
    flex-direction: row;
}

.button-group > .button,
.button-group > .input {
    position: relative;
    border-radius: 0;
    margin-left: -1px;
}

.button-group > .input {
    flex: 1 1 auto;
}

.button-group > .button {
    flex: 0 0 auto;
}

.button-group > .button:hover,
.button-group > .input:hover {
    z-index: 10;
}

.button-group > .button:first-child,
.button-group > .input:first-child {
    border-radius: $border-radius 0 0 $border-radius;
    margin-left: 0;
}

.button-group > .button:last-child,
.button-group > .input:last-child {
    border-radius: 0 $border-radius $border-radius 0;
}
