.tides {
    position: relative;
    width: 165px;
    margin-right: 35px;
}

.tides__main {
    position: relative;
    display: flex;
    max-height: 208px;
    padding: 10px;
    background-color: $color-black;
}

.tides__img {
    width: 160px;
}

.tides__txt {
    display: flex;
    justify-content: center;
    padding: 3px;
    margin-top: 2px;
    color: $font-color-light;
    background: $color-black;
}

.vol-container {
    position: absolute;
    top: 10px;
    right: -46px;

    display: flex;
    flex-direction: column;
    height: auto;
    padding: 7px 11px;
}

.vol {
    position: relative;
    transform: rotate(-6deg);
    font-family: $font01;
    line-height: pxToRem(15);
    font-size: pxToRem(15);
    color: #fff;
    background-color: #000;
    transition: .4s ease;
    display: inline;
    padding: 2px 10px 1px 10px;
    &:not(:last-child) {
        margin-bottom: 6px;
    }

    //&::before {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    left: 50%;
    //    transform: translateX(-50%);
    //    display: block;
    //    width: 160%;
    //    height: 100%;
    //    z-index: -10;
    //    background-color: $color-black;
    //    transition: .4s ease;
    //}

    &:hover {
        color: $color-primary;

    }

    //&::after {
    //    content: '';
    //    position: absolute;
    //    top: 0;
    //    left: 1px;
    //    display: block;
    //    width: 95%;
    //    height: 100%;
    //    z-index: -1;
    //    background-color: $color-white;
    //}
}