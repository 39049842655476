@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;

    }

    h1, .h1, h2, .h2 {
        // margin-bottom: pxToRem(8);
        // line-height: 1.2;
        //font-family: $font01;
    }

    h5, .h5, h6, .h6 {
        //margin-bottom: pxToRem(16);
        //line-height: 1.3;
    }

    h2, .h2 {
        //  font-size: pxToRem(40);
    }

    h3, .h3 {
        //  font-size: pxToRem(30);
    }

    h4, .h4 {
        //  font-size: pxToRem(25);
        margin-bottom: $spacing-s;
    }

    h5, .h5 {
        // font-size: pxToRem(20);
    }

    h6, .h6 {
        //   font-size: pxToRem(16);
    }

    p {
        //line-height: 1.6em;
        word-break: break-word;
    }

    p {
        margin-bottom: 28px !important;
    }

    p, ul, ol, hr, table {
        //margin-bottom: pxToRem(24);
    }

    hr {
        // border-top: 1px solid $color-dark;
    }

    a {
        //text-decoration: underline;
    }

    strong {
        font-weight: bold;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(10);
    }

    ul {
        padding-left: pxToRem(18);
        list-style-type: disc;
    }

    ul li {
        padding-left: pxToRem(10);
        position: relative;
    }

    //ul li:before {
    //    content: '\00a0';
    //    position: absolute;
    //    top: 0;
    //    left: -1.2rem;
    //    display: block;
    //    width: 1.2rem;
    //    height: 1.6rem;
    //    background-image: inline-svg('check', $color-gray-60);
    //    background-repeat: no-repeat;
    //    background-size: 0.8rem 0.8rem;
    //    background-position: 0 center;
    //}

    ul li::marker,
    ol li::marker {
        font-size: pxToRem(16);
        color: $color-primary;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        text-align: left;
        //border: 1px solid $color-gray-15;
        border: none;
        margin-bottom: 32px;
        width: 100%;
        //display: block;
        overflow-x: auto;
    }

    table tbody {
        //display: table;
    }

    table th,
    table td,
    table thead th,
    table tbody td {
        text-align: left;
        background: transparent;
    }

    tr:not(:last-child) {
        border-bottom: 2px solid $color-black;
    }

    thead tr:last-child {
        border-bottom: 4px solid $color-black;
    }

    th, td {
        padding: 12px 12px;
    }

    th:not(:last-child),
    td:not(:last-child) {
        border-right: 2px solid $color-black;
    }
    em {
        font-style: italic;
    }

    @include media-breakpoint-down(sm) {
        table {
            display: block;
        }
    }

}
