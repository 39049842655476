.downloads {
    margin-top: $spacing-xl;
}

.downloads h3 {
    display: inline-block;
    margin-bottom: 12px;
}

ul.downloads__items {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-top: 32px;

    li {
        padding-left: 0;
        list-style-type: none;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}

.download__link {
    margin-bottom: 16px;
}