.cta__items {
    margin-top: 63px;
}

.cta__button {
    margin-bottom: 10px;
}

@include media-breakpoint-down(sm) {
    .cta__items {
        margin-top: 35px;
    }
    .button {
        margin-bottom: 16px;
    }
}