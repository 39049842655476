.layout-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $color-white;
}

.error {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 150px 30px;
    text-align: center;
}

.error-msg {
    margin-top: 28px;
}

.error h1 {
    display: inline;
    padding: 0 10px;
    color: $font-color-light;
    background-color: $color-danger;
    text-transform: uppercase;
}

.error p {
    font-size: $base-font-size;
}