.stoerer {
    position: absolute;
    max-width: 350px;
    width: 100%;
    height: auto;
    z-index: 50;

    &:hover {
        animation-name: shake;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        transform-origin: center center;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }
}

.stoerer--1 {
    right: 100px;
    top: 190px;
}

.stoerer--2 {
    left: 100px;
    bottom: 110px;
}

@keyframes shake {
    0% {
        transform: translate(1px, 0) rotate(0deg);
    }

    10% {
        transform: translate(0, -1px) rotate(-1deg);
    }
    20% {
        transform: translate(-2px, -1px) rotate(1deg);
    }
    30% {
        transform: translate(-1px, -1px) rotate(0deg);
    }
    40% {
        transform: translate(0, 0) rotate(1deg);
    }
    50% {
        transform: translate(0, 1px) rotate(-1deg);
    }
    60% {
        transform: translate(-2px, 0) rotate(0deg);
    }
    70% {
        transform: translate(1px, 0) rotate(-1deg);
    }
    80% {
        transform: translate(0, 0) rotate(1deg);
    }
    90% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    100% {
        transform: translate(0, -1px) rotate(-1deg);
    }
}

@include media-breakpoint-down(xl) {
    .stoerer--1 {
        top: initial;
        right: 50px;
        bottom: 110px;
    }

    .stoerer--2 {
        display: none;
    }
}

@include media-breakpoint-down(lg) {
    .stoerer {
        width: 300px;
    }
}

@include media-breakpoint-down(sm) {
    .stoerer {
        max-width: 250px;
    }

    .stoerer--1 {
        right: 15px;
        bottom: 60px;
        //max-width: 275px;
    }
}
