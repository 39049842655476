.footer {
    width: 100%;
}

.footer__bg {
    background-color: $color-footer;
}

.footer > .container,
.footer > .container > .grid {
    //height: 80px;
}

/* || Navigation */

.footer__navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 90px;
}

.footer__navigation ul {
    display: flex;
    justify-content: flex-end;
    width: calc(100% + 16px);
    margin: 0 -8px;
}

.footer__navigation ul > li {
    display: inline-block;
    padding: 0 8px;

    &:not(:last-child) {
        margin-right: 34px;
    }
}

.footer__navigation ul > li a {
    font-size: pxToRem(15);
    color: $font-color-light;

    &:hover {
        text-decoration: underline;
    }
}

.footer__socials {
    display: flex;

    a {
        width: 50px;
        height: 50px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

/* || Main */

.footer__main {
    position: relative;
    z-index: 10;
    padding: $spacing-xl 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100vw;
        height: 100%;
        margin-left: calc(50% - 50vw);
        border-top: 3px dashed $color-black;
        border-bottom: 3px dashed $color-black;
        background-image: url('/assets/img/bg-footer.png');
        background-position: center center;
        background-size: cover;
        background-color: #333333;
        z-index: -1;
    }
}

.footer__slotmachine {
    .slotmachine {
        position: relative;
        width: 258px;
        height: 254px;
    }

    .slotmachine__slots {
        position: absolute;
        top: 62px;
        width: 226px;
        height: 159px;
    }

    .slotmachine__slot {
        position: absolute;
        top: 0;
        left: 0;
    }
}

//n: Anzahl images
//a: Anzeigedauer (1s)
//b: Dauer des Fadings (0.5s)
//t=(a+b)*n: Gesamte Animationsdauer

@keyframes slotsFadeInOut {
    0% {
        opacity: 1;
    }
    // a/t*100
    7.4% {
        opacity: 1;
    }
    // (a+b)/t*100 oder 1/n*100
    11.1% {
        opacity: 0;
    }
    // 100-(b/t*100)
    96.3% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#slotmachine__slots img {
    animation-name: slotsFadeInOut;
    animation-duration: 13.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    &:nth-of-type(1) {
        animation-delay: 12s;
    }

    &:nth-of-type(2) {
        animation-delay: 10.5s;
    }

    &:nth-of-type(3) {
        animation-delay: 9s;
    }

    &:nth-of-type(4) {
        animation-delay: 7.5s;
    }

    &:nth-of-type(5) {
        animation-delay: 6s;
    }

    &:nth-of-type(6) {
        animation-delay: 4.5s;
    }

    &:nth-of-type(7) {
        animation-delay: 3s;
    }

    &:nth-of-type(8) {
        animation-delay: 1.5s;
    }

    &:nth-of-type(9) {
        animation-delay: 0s;
    }
}

.footer__newsletter {
    .input-group {
        height: 50px;
    }

    input {
        height: auto;
        border-color: $color-white;
    }

    &--headline {
        display: inline-block;
        transform: rotate(-6deg);

        span {
            font-family: $font01;
            line-height: pxToRem(20);
            font-size: pxToRem(20);
            -webkit-backface-visibility: hidden;
            color: #fff;
            background: #000;
            text-transform: uppercase;
            padding: 5px 15px 4px 15px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }

    }

    &--content {
        margin-top: $spacing-m;
        font-size: pxToRem(15);
        color: $font-color-light;
    }

    .input-group-append {
        background-color: $color-white !important;
        padding-right: 10px;
        border: 1px solid $color-white;
        border-left: none;
        transition: border .15s ease;
    }

    .btn--footer-newsletter + span {
        position: absolute;
        right: 15px;
        z-index: 10;
        display: block;
        width: 12px;
        height: 12px;
        background-image: inline-svg('angle-s', $color-white, transparent);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: .4s ease;
    }

    .btn--footer-newsletter:hover + span {
        right: 20px;
    }

    .formular-item__input:hover + .input-group-append {
        border: 1px solid $color-primary;
        border-left: none;
    }
}

.footer-newsletter__form {
    margin-top: $spacing-s;
}

.footer__tides {
    display: flex;
    justify-content: flex-end;
}

/* || Impressum */

.footer__imprint {
    display: flex;
    align-items: center;
    height: 60px;
}

.footer__imprint--contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $font-color-light;
    font-size: pxToRem(15);
    font-style: normal;

    a {
        color: $font-color-light;
    }
}

/* || Awards */

.footer__awards {
    position: relative;
    z-index: 10;
    padding: $spacing-xl 0;

    img {
        width: auto;
        height: auto;
    }

    &--container {
        display: flex;
        justify-content: space-between;
    }

    .award__1, .award__2, .award__3, .award__4 {
        width: auto;
        height: auto;
        margin-right: 30px;
        margin-bottom: 15px;
    }

    &--left {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .award__1 {
            max-width: 75px;
        }

        .award__2 {
            max-width: 59px;
        }

        .award__3 {
            max-width: 85px;
        }

        .award__4 {
            max-width: 108px;
        }
        //
        //img:not(:last-child) {
        //    margin-right: 30px;
        //    margin-bottom: 15px;
        //}
    }

    &--right {
        .award__1 {
            max-width: 217px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer__slotmachine {
        display: flex;
        justify-content: center;
        padding-bottom: $spacing-l;

        .slotmachine {
            margin-left: 30px;
        }
    }

    .footer__tides {
        justify-content: center;
        margin-top: $spacing-l;
    }

    .footer__imprint {
        height: auto;
        padding: 36px 10px;
    }

    .footer__imprint--contact {
        flex-direction: column;
        align-items: center;
        justify-content: initial;
        text-align: center;
    }

    .footer__imprint--phone, .footer__imprint--cta {
        margin-top: $spacing-m;
    }
}

@include media-breakpoint-down(md) {
    .footer__navigation {
        flex-direction: column;
        height: auto;
        padding: $spacing-m 0;

        .footer__socials {
            margin-bottom: $spacing-m;
        }

        ul {
            flex-direction: column;
            justify-content: initial;
            align-items: center;

            > li:not(:last-child) {
                margin-right: initial;
                margin-bottom: 16px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer__awards--container {
        flex-direction: column;
        align-items: center;
    }

    .footer__awards--left {
        justify-content: center;
    }

    .footer__awards--right {
        margin-top: $spacing-m;
    }

    .teaser-slider + footer {
        margin-top: -64px;
    }
}
