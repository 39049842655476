.header__navigation {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__navigation ul {
    //width: calc(100% + 16px);
    margin: 0 -8px;

    ul {

        @include media(xl) {
            opacity: 0;
            pointer-events: none;
            display: block;
            position: absolute;
            top: 64px;
            transition: opacity .4s ease;

            &::before {
                content: '';
                position: absolute;
                height: 50px;
                width: 100%;
                top: -45px;
            }

            &::after {
                content: '';
                position: absolute;
                top: -9px;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                height: 9px;
                width: 12px;
                border-style: solid;
                border-width: 0 12px 9px 12px;
                border-color: transparent transparent $color-primary transparent;

            }
        }
    }
}

.header__navigation > ul > li {
    display: inline-block;
    padding: 0 16px;
    position: relative;

    //&:not(:last-child) {
    //    margin-right: 4px;
    //}

    > a {
        display: inline-block;
        color: $font-color-light;
        text-transform: uppercase;
        position: relative;
        transition: transform .4s ease;

        //&::after {
        //    content: '';
        //    position: absolute;
        //    top: 50%;
        //    right: -17px;
        //    display: block;
        //    width: 12px;
        //    height: 12px;
        //    background-image: inline-svg('angle-s', $color-white, transparent);
        //    background-size: 12px 12px;
        //    transform: rotate(90deg) translateX(-50%);
        //}
    }

    &.level-0::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        //transform: translateX(calc(-50% + 8px));
        transform: translateX(-50%);
        display: block;
        width: 0;
        height: 3px;
        background-color: $color-primary-hover;
        opacity: 0;
        transition: all .4s ease;
    }

    &.level-0:hover::after {
        opacity: 1;
        //width: calc(100% - 16px);
        width: calc(100% - 32px);
    }

    &.level-0:hover > a {
        transform: translateY(-8px);
        transition: all .4s ease;
    }

    &:hover ul {
        opacity: 1;
        pointer-events: auto;
        z-index: 2;
    }
}

.header__navigation ul ul li {
    display: block;
}

.burger {
    display: none;
    transition: all 1s ease;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: $color-white !important;
}

.plus {
    display: none;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    bottom: 7px;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('/assets/img/plus.svg');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}

.header__select--standort {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: $spacing-xl;
    font-size: pxToRem(15);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    .standort {
        position: relative;
        padding: 9px 46px 9px 16px;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(90deg);
            width: 30px;
            height: 30px;
            background-image: inline-svg('angle-s', $color-black, transparent);
            background-color: $color-white;
            background-size: 12px 12px;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:hover::before {
            content: '';
            position: absolute;
            top: 30px;
            display: block;
            height: 50px;
            width: 100%;
        }

        &:hover .standort__standort {
            opacity: 1;
            pointer-events: auto;
        }

        .standort__auswahl {
            color: $font-color-light;
        }
    }

    .standort__standort {
        position: absolute;
        top: 73px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity .4s ease;
        white-space: nowrap;

        &::before {
            content: '';
            position: absolute;
            top: -9px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            height: 9px;
            width: 12px;
            border-style: solid;
            border-width: 0 12px 9px 12px;
            border-color: transparent transparent $color-primary transparent;
        }
    }

    ul li {
        color: $font-color-light;

        a {
            font-size: pxToRem(15) !important;
        }
    }
}

.header__nav--menu {
    ul {
        position: absolute;
        top: 64px;
        left: calc(50% + 16px);
        transform: translateX(-50%);
        display: block;
        width: auto;
        min-width: 210px;
    }

    li:not(.level-0) {
        display: flex;
        background-color: $color-secondary;
        transition: .4s ease;

        &:not(:last-child) {
            margin-bottom: 2px;
        }

        &:first-child a {
            padding-top: 18px;
        }

        &:last-child a {
            padding-bottom: 18px;
        }

        &:hover {
            background-color: $color-secondary-hover;
        }

        a {
            flex: 1;
            padding: 12px;
            text-align: center;
            font-size: pxToRem(16);
            color: $font-color-light;
        }
    }
}

.header__container--buttons {
    display: flex;
    margin-top: 15px;
}

.header__ctas {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    z-index: 10;
}

.header__booknow {
    margin-right: 0 !important;
    box-shadow: 0 0 12px 0 rgba($color-black, .25);
}

.header__gutscheine {
    margin-top: 4px;
    margin-right: 0 !important;
    line-height: 28px;
    font-size: pxToRem(20) !important;
    box-shadow: 0 0 12px 0 rgba($color-black, .25);
}

.header__booknow--scrolled {
    display: none !important;
}

.header__call {
    display: none;
}

.navigation__mobile--container, .navigation__mobile--contact {
    display: none;
}

.js-nav-scrolled02 {
    .header__container--buttons {
        margin-top: initial;
        align-items: center;
    }

    .header__booknow {
        display: none;
    }

    .header__gutscheine {
        display: none;
    }

    .header__booknow--scrolled {
        display: flex !important;
    }
}

.header__select-standort--mobile {
    display: none;
}

@media(max-width: 1420px) {
    .header__navigation > ul > li {
        padding: 0 8px;
    }

    .header__select--standort {
        margin-right: $spacing-m;
    }
}

@media(max-width: 1300px) {
    .header__navigation > ul > li {
        padding: 0 4px;
    }

    .header__select--standort {
        margin-right: 20px;
    }
    .header > .container {
        padding: 0 45px !important;
    }
}

@include media-breakpoint-down(xl) {
    .burger {
        display: block;
        position: absolute;
        left: 12px;
        top: 10px;
        z-index: 600;
    }
    .plus {
        display: block;
    }
    .js-subnav-open .plus {
        transform: rotate(-45deg);
    }
    .js-nav-open {
        .header__navigation {
            left: 0;
        }

        .navigation__mobile--container {
            position: absolute;
            top: 0;
            right: 16px;
            display: flex;
        }
    }
    .header__navigation {
        position: fixed;
        z-index: 550;
        left: -100vw;
        top: 0;
        width: 100%;
        min-height: 100vh;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
        background: $color-secondary;
        transition: all .6s ease;
        overflow: hidden auto;

        > ul {
            padding: 0 15px;
        }

        ul {
            display: inline-block;
            width: 100%;

            li {
                display: block;
                text-align: center;
                font-size: pxToRem(30);
                padding: 0;

                a {
                    color: $color-white;
                    width: 100%;
                }
            }
        }

        ul ul {
            display: none;
            margin: 0;

            li {
                font-size: pxToRem(20);
                padding: 2px 0;
                border-bottom: 1px solid $color-secondary-hover;
            }
        }
    }

    .header__navigation {
        .level-0 {
            font-family: $font03;
            text-transform: uppercase;
            font-size: pxToRem(24);

            &:not(:last-child) {
                margin: 0 0 $spacing-s !important;
            }

            &::after {
                content: none !important;
            }

            > a {
                padding: 0 0 12px;
                border-bottom: 1px solid $color-primary;
            }

            a::after {
                content: none;
            }

            &:hover a {
                transform: initial !important;
            }
        }
    }

    .header__nav--menu {
        li ul li a {
            position: relative;
            padding: 20px 0 10px !important;
            line-height: pxToRem(25);
            font-family: $font02;
            font-size: pxToRem(18) !important;
            text-transform: uppercase;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 8px;
                height: 8px;
                background-image: inline-svg('angle-xs', $color-white, transparent);
                background-size: 8px 8px;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .navigation__mobile--contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $spacing-l 0;

        .navigation__logo {
            width: 94px;
            height: auto;
        }

        .navigation__contact {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 16px;
            font-size: pxToRem(15);
            color: $font-color-light;

            span:not(:first-child) {
                margin-top: 8px;
            }

            a {
                color: $font-color-light;
            }
        }

        .navigation__socials {
            display: flex;
            margin-top: 20px;

            a {
                width: 50px;
                height: 50px;

                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
    }

    .header__select--standort {
        display: none;
    }

    .header__call {
        position: absolute;
        top: 10px;
        right: 12px;
        display: block;
    }

    .header__container--buttons {
        width: 100%;
        justify-content: center;
        margin-top: 65px;

        .standort__auswahl span {
            background-color: #4D4D4D;
        }

        .standort__standort {
            background-color: #4D4D4D;
        }
    }

    .header__ctas {
        flex-direction: row-reverse;

        .header__gutscheine {
            height: initial !important;
            margin-top: initial;
            margin-right: 4px !important;
            word-break: break-word;
        }
    }

    .header__booknow, .header__gutscheine {
        font-size: pxToRem(20) !important;
    }

    .js-nav-scrolled02 .header {
        .header__logo {
            display: none;
        }

        .header__container--buttons {
            margin-top: 50px !important;
        }

        .header__booknow, .header__gutscheine {
            display: flex;
            font-size: pxToRem(20) !important;
        }

        .header__booknow--scrolled {
            display: none !important;
        }
    }

    .header__nav--menu {
        margin-top: $spacing-s !important;
        ul {
            position: relative;
            top: 0;
            left: initial;
            transform: initial;
        }
    }

    .header__select-standort--mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .standort__standort {
            position: relative;
            display: none;
            margin-top: 8px;
        }

        .standort__auswahl {
            position: relative;
            width: 100%;
            padding: 18px 0;
            text-align: center;
            font-size: pxToRem(15);
            line-height: 140%;
            color: $font-color-light;
            background-color: $color-secondary-dark;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 14px;
                transform: translateY(-50%) rotate(90deg);
                width: 30px;
                height: 30px;
                background-image: inline-svg('angle-s', $color-black, transparent);
                background-color: $color-white;
                background-size: 12px 12px;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        ul > li {
            margin: 0 8px;
            background-color: $color-white;

            &:not(:first-child) {
                margin-top: 2px;
            }

            &:first-child::before {
                content: '';
                position: absolute;
                top: -9px;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                height: 8px;
                width: 12px;
                border-style: solid;
                border-width: 0 12px 9px 12px;
                border-color: transparent transparent $color-primary transparent;
            }

            &:first-child a {
                padding-top: 18px;
            }

            &:last-child a {
                padding-bottom: 18px;
            }

            a {
                display: block;
                height: 100%;
                width: 100%;
                padding: 12px 0;
                font-size: pxToRem(15);
                line-height: 140%;
                color: $font-color;
            }
        }
    }

    .header > .container {
        padding: 0 8px !important;
    }
}

@include media-breakpoint-down(sm) {
    .header__ctas {
        width: 100%;
        a {
            width: 100%;
        }
    }

    .burger, .header__call {
        top: 2px;
    }

    .header__container--buttons {
        margin-top: 50px;
    }

    .header__ctas .header__booknow, .header__ctas .header__gutscheine {
        font-size: pxToRem(18) !important;
        height: 90px !important;
        hyphens: auto;
    }

    .js-nav-scrolled02 .header__ctas .header__booknow, .js-nav-scrolled02 .header__ctas .header__gutscheine {
        font-size: pxToRem(18) !important;
    }
}
