#CybotCookiebotDialog {
    border-radius: $border-radius !important;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 30%);
}

#CybotCookiebotDialogHeader {
    display: none !important;
}

#CybotCookiebotDialog img {
    width: auto;
}

#CybotCookiebotDialog h2 {
    transform: rotate(0) !important;
}

/**
 * Nav
 */

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
    /* Variante A: sieht schöner aus für dunkle Farben */
    color: $color-primary !important;
    /* Variante B: je nach Farbe evtl. besser lesbar */
    //color: dynamic-color-text($color-white) !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
    color: $color-primary !important;
    //color: dynamic-color-text($color-white) !important;
    border-bottom: 2px solid $color-primary !important;
}

/**
 * Slider Heading
 */

#CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
    color: $color-primary !important;
    //color: dynamic-color-text($color-white) !important;
}

/**
 * Slider
 */

#CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-gray-20 !important;
}

#CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-primary !important;
}

#CybotCookiebotDialogTabContent input[type=checkbox][disabled]:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: $color-gray-20 !important;
}

/**
 * Buttons
 */

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    background-color: $color-white !important;
    color: dynamic-color-text($color-white) !important;
    border: 1px solid $color-primary !important;
    border-radius: $border-radius-button !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept, #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    background-color: $color-primary !important;
    color: dynamic-color-text($color-primary) !important;
    border: 1px solid $color-primary !important;
    border-radius: $border-radius-button !important;
}

