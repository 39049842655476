.message h3 {
    display: inline;
    padding: 0 10px;
    color: $font-color-light;
    text-transform: uppercase;
}

.message p {
    margin-top: 10px;
}

.message--error h3 {
    background-color: $color-danger;
}

.message--warning h3 {
    background-color: $color-warning;
}

.message--success h3 {
    background-color: $color-success;
}

.message--info h3 {
    background-color: $color-info;
}
