/* || Facts */
.room__facts {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0 !important;
    margin: $spacing-m 0 0;
    li {
        font-family: $font03;
        position: relative;
        list-style-type: none;
        line-height: pxToRem(28);
        font-size: pxToRem(20);
        text-transform: uppercase;
        margin: 0 0 $spacing-l $spacing-xl;
        padding-left: 0 !important;
        max-width: 300px;

        &:not(:last-child) {
            margin-right: $spacing-xl;
        }

        &:nth-child(odd) {
            transform: rotate(-3deg);
        }

        &:nth-child(even) {
            transform: rotate(3deg);
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -$spacing-xl;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            background-image: inline-svg('check-circled', $color-white, transparent);
            background-size: 32px 32px;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: $color-primary;
            border-radius: 50%;
        }
    }
}

/* || Preis */

.room__price {
    display: inline-flex;
    transform: rotate(-3deg);
    margin-top: $spacing-s;
    span {
        &:after, &:before {
            display: none;
        }
    }
    .price__container {

        padding: 0 20px;
        margin-bottom: 10px;
    }

    .price__prefix, .price__price {
        line-height: pxToRem(28);
        font-size: pxToRem(28);
        color: $font-color;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        padding: 8px 22px 6px 22px;
        color: #fff;
        background: #000;
    }

    .price__prefix {
        margin-right: -18px;

    }

    .price__suffix {
        font-size: pxToRem(18);
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        color: #fff;
        background: #000;
        padding: 8px 15px 6px 15px;
        display: inline-block;
    }
}

/* || Booking Buttons */

.room__booking {
    //display: flex;
    padding: $spacing-m 0;
}

.room__booking--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.room__booking--gutscheine {
    height: 90px !important;
    margin: 10px 28px 10px !important;
    font-size: pxToRem(24) !important;
    width: 360px;
}

.room__booking--booknow {
    margin-right: initial !important;
    width: 360px;
}

.fb-room__outer {
    display: none;
    padding: 28px 0 12px;
}

.room__booking--container--bg {
    background: $color-secondary;
}

.room__booking--form {
    max-width: 360px;
}

.fb-room__input-outer {
    display: flex;

    .fb-room__input:not(:last-child) {
        margin-right: 8px;
    }
}

.fb-room__input {
    .date-bottom {
        width: 100%;
        height: 150px;
    }
}

.fb-room__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
        margin-top: 12px;
    }

    &:not(:last-child) {
        margin-bottom: 4px;
    }
}

.fb-room__item--radios {
    margin-top: 10px;
}

.fb-room__item--radios .fb-room__item {
    flex-direction: column;
    align-items: flex-start;
    color: $font-color-light;
    font-family: $font03;
    text-transform: uppercase;

    .radio-item__container {
        display: flex;
    }

    .radio {
        display: inline-flex;
        width: auto;
        margin-right: 12px;
    }
}

.fb-room__input-wrap {
    position: relative;
}

#fbPickerDateFromRoom, #fbPickerDateToRoom {
    cursor: pointer;
    &:hover, &:focus {
        border: 1px solid $color-primary;
    }
}

.fbPickerDateFromRoom--coverup, .fbPickerDateToRoom--coverup {
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 2px);
    height: 148px;
    background-color: $color-white;
    pointer-events: none;
}

.roomDateFrom--day, .roomDateTo--day {
    font-family: $font03;
    font-size: 48px;
    margin-top: 12px;
}

.roomDateFrom--monthYear, .roomDateTo--monthYear {
    padding: 12px;
    font-size: pxToRem(17);
    text-transform: uppercase;
    white-space: nowrap;
}

.roomDateFrom--monthYear::after, .roomDateTo--monthYear::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 8px;
    width: calc(100% - 16px);
    height: 1px;
    background-color: #D9D9D9;
}

.fb-room__label {
    font-family: $font03;
    text-transform: uppercase;
    font-size: pxToRem(18);
    color: $font-color-light;
}

.fb-room__select {
    width: 150px;
    height: 43px;
    select {
        width: 100%;
        height: 100%;
    }

    input {
        width: 150px;
        height: 100%;
        border: none;
        padding: 4px;
    }
}


@include media-breakpoint-down(md) {
    .room__facts {
        flex-direction: column;
        margin-top: $spacing-m;

        li:not(:first-child) {
            margin-top: $spacing-m;
        }
    }

    .room__price {
        display: flex;
    }
}

@include media-breakpoint-down(sm) {
    .room__booking--booknow, .room__booking--gutscheine {
        width: 100%;
        justify-content: center !important;
    }

    .room__booking--gutscheine {
        margin: 10px 0 !important;
    }

    .room__booking--booknow {
        white-space: initial;
        text-align: center;
    }

    .room__facts li {
        margin-bottom: $spacing-s;
    }
}