.container--fluid {
    max-width: inherit !important;
}

.subline {
    font-family: $font03;
    font-size: pxToRem(26);
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: $spacing-s;

    &--subline {
        margin-top: $spacing-s;
    }
}

.article__container {
    margin-top: 96px;
    margin-bottom: 128px;

    h1, h2 {
        display: inline-block;
        transform: rotate(-6deg);
        margin-bottom: $spacing-m;
        text-align: center;
    }
}

.col-article-hl > div {
    width: 70%;
}

.image.mime-svg, .image.mime-png {
    z-index: -1;
    background: transparent;
}

.introtext__img img {
    object-fit: initial;
}

.introtext__img .image::before {
    content: none;
}

.introtext__img--text-outer {
    position: absolute;
    bottom: -20px;
    right: 0;
    z-index: 10;
    transform: rotate(10deg);
    text-align: center;

}

.introtext__img--text {

    font-family: $font01;
    font-size: pxToRem(30);
    text-align: center;
    line-height: 1.7;
    display: inline;
    padding: 5px 15px 4px 15px;
    color: #fff;
    background: #000;
    text-transform: uppercase;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    //& > div {
    //    display: block;
    //    line-height: 1;
    //    margin-bottom: 5px;
    //    -webkit-backface-visibility: hidden;
    //}
    //div div {
    //    background: $color-black;
    //    padding: 0 15px;
    //    display: inline;
    //    -webkit-backface-visibility: hidden;
    //}
    //
    //span {
    //    background: $color-white;
    //    display: inline-block;
    //    line-height: 1;
    //}
}

.booking__url--container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 96px;

    .button:not(.button--link) {
        margin-right: initial;
    }
}

.article__container + .booking__url--container {
    margin-top: -96px;
}

//Datenschutz + Impressum HHF, Büsum, Portal
.pageid-2218f099-3715-4542-a87c-79c3a016350f .content, .pageid-9fdb7903-e50d-4f81-8cdc-a69d1b97c9ac .content,
.pageid-6f0362c9-39b2-4b87-840a-74e8644649f3 .content,
.pageid-de4059c5-785f-41c4-a1ea-466f899c8807 .content,
.pageid-9a63c37a-8d3f-45a4-a384-5961b08798f7 .content,
.pageid-4e8f2584-5bbd-482e-9229-4be67e13eaa4 .content {
    h3 {
        font-family: $font03;
        text-transform: uppercase;
        font-size: pxToRem(30);
        transform: none;
        margin-top: 55px;

        & + p {
            margin-top: 16px;
        }

        & + h4 {
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }

    h4 {
        margin-bottom: 8px;
    }
}

.messenger__iframe {
    margin-top: 36px !important;
}

@include media-breakpoint-down(lg) {
    .introtext__img {
        margin-top: -96px;
    }
}

@include media-breakpoint-down(md) {
    .col-article-hl > div {
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .subline {
        margin-top: 28px;
    }
    .article__main {
        text-align: center;
    }
    .introtext__img {
        position: relative;
        z-index: -1;
        margin-top: -96px;
    }

    .article__container {
        margin-top: $spacing-xl;
        margin-bottom: $spacing-xl;

        & + .booking__url--container {
            margin-top: -64px;
        }
    }

    .article__headline {
        display: flex;
        justify-content: center;
    }
}