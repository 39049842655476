.header {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background: rgba($color-black, .4);
    box-shadow: 0 0 4px rgba($color-dark, 0.15);
}


.header > .container,
.header > .container > .grid {
    height: 100%;
}

.header__logo {
    height: 100%;
    width: 94px;
    display: none;
}

.js-nav-scrolled02 .header {
    height: 70px;
    background: rgba($color-black, .6);

    .header__logo {
        display: flex;
        align-items: center;
    }

    .header__select--standort {
        display: none;
    }
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled .header {
        transform: translateY(-100%);
    }
}

.js-nav-scrolled02 .header {
    transform: translateY(0);
    transition: all 1s ease;
    position: fixed;
}

.header__language {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
    margin-right: 50px;
    transition: opacity 0.3s;
}

.js-nav-scrolled .header__language {
    //opacity: 0;
}

.header__language ul > li {
    display: inline-block;
    text-transform: uppercase;
}

.header__language ul > li:not(:last-child) {
    margin-right: 5px;
}

.header__language ul > li a,
.header__language ul > li span {
    position: relative;
}

.header__language ul > li a {
    color: rgba($color-white, 0.6);
}

.header__language ul > li span {
    color: $color-white;
}

.header__language ul > li a:before,
.header__language ul > li span:before {
    content: '\00a0';
    position: absolute;
    left: -3px;
    bottom: -2px;
    display: block;
    width: calc(100% + 6px);
    height: 2px;
    transform: rotate(10deg);
}

.header__language ul > li:nth-child(2n) a:before,
.header__language ul > li:nth-child(2n) span:before {
    transform: rotate(-6deg);
}

.header__language ul > li a:before {
    opacity: 0;
    background: rgba($color-white, 0.6);
}

.header__language ul > li a:hover:before {
    opacity: 1;
}

.header__language ul > li span:before {
    background: $color-white;
}

@include media-breakpoint-down(xl) {
    .header {
        position: fixed;
        background: rgba($color-black, .7);
        transition: all 1s ease;
    }

    .js-nav-scrolled .header {
        position: fixed;
        transition: all 1s ease;
    }

    .js-nav-scrolled02 .header {
        transform: initial;
    }
}

@include media-breakpoint-down(sm) {
    .header {
        height: 66px;
    }
}
