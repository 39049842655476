$header-height: 80px;
$header-height-mobile: 66px;

.keyvisual {
    min-height: 200px;
    position: relative;
    height: auto; /*exact img size*/
}

.keyvisual--is-empty {
    height: $header-height !important;
}

.keyvisual .image {
    position: unset;
    height: 100%;
}

.keyvisual .swiper {
    overflow: hidden !important;
    height: 100%;
}

.keyvisual .swiper-button-prev, .keyvisual .swiper-button-next {
    display: none;
}

.keyvisual__slide.swiper-slide-next .image, .keyvisual__slide.swiper-slide-prev .image, .keyvisual__slide.swiper-slide-active .image {
    visibility: visible;
}

.keyvisual__slide .image{
    visibility: hidden;

}

.keyvisual__slide img,
.keyvisual__slide picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

body[class*="view-frontpage"] .keyvisual,
.keyvisual__big {
   // height: calc(100vh - 80px /*HeaderHeight*/);
}

.kv__logo {
    position: absolute;
    top: 124px;
    left: $spacing-m;
    width: 194px;
    height: 201px;
    z-index: 50;
}

.kv__scroll {
    position: absolute;
    bottom: $spacing-m;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;

    &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: bounce 1s infinite 500ms;
    }
}

.kv__scroll--text {
    font-family: $font01;
    line-height: pxToRem(15);
    font-size: pxToRem(15);
    color: #fff;
    background: #000;
    text-transform: uppercase;
    display: inline;
    padding: 3px 10px 2px 10px;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.kv__scroll--button {
    width: 50px;
    height: 50px;
    margin-top: 16px;
    border-radius: 50%;
    background-color: rgba($color-primary, .6);
    background-image: inline-svg('arrow-down', $color-white);
    background-size: 24px 25px;
    background-position: center center;
    background-repeat: no-repeat;
}

.keyvisual .swiper-pagination {
    display: none;
}

@include media-breakpoint-up(md) {

    .keyvisual {
        //height: calc(70vh - 80px /*HeaderHeight*/);
        height: calc(70vh - 80px /*HeaderHeight*/);
    }

    body[class*="view-frontpage"] .keyvisual,
    .keyvisual__big {
        //height: calc(90vh - 80px /*HeaderHeight*/);
        height: 100vh;
    }

}

@include media-breakpoint-down(xl) {
    .keyvisual {
        //margin-top: $header-height;
    }
}

@include media-breakpoint-down(lg) {
    .kv__scroll {
        display: none;
    }
}

@include media-breakpoint-down(sm) {
    .keyvisual {
        padding-top: $header-height-mobile;
    }

    .kv__logo {
        top: 156px;
        left: 8px;
        width: 135px;
    }
}


