
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    overflow-x: hidden;
    font-family: $font02;
    font-weight: 400;
    color: $font-color;
    background-color: $color-white;
    background-image: url("/assets/img/bg-page.jpg");
    background-attachment: fixed;
}

body.pageid-de35b29a-eac0-4429-8d49-0f04fac843b4 {
    background-image: url("/assets/img/bg-sand.jpg");
    background-color: #efebe0;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(md) {
        background-image: url("/assets/img/bg-sand-mobile.jpg");
        background-color: #efebe0;
    }
}

.css-transitions-only-after-page-load *:not(img, picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none;
    hyphens: auto;
}


h1, .h1, h2, .h2, h3, .h3 {
    font-size: pxToRem(32);
    font-family: $font01;
    transform: rotate(-6deg);
    line-height: 1.2;

    span {
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        //background: #fff;
        //border-left: 15px solid #000;
        //border-right: 15px solid #000;
        display: inline;
        background: #000;
        color: #fff;
        padding: 6px 15px 5px 15px;
        line-height: 1.8;
        text-transform: uppercase;

    }
    //& > div {
    //    display: block;
    //    line-height: 1;
    //    margin-bottom: 5px;
    //    -webkit-backface-visibility: hidden;
    //}
    //div div {
    //    background: $color-black;
    //    padding: 0 15px;
    //    display: inline;
    //    -webkit-backface-visibility: hidden;
    //}
    //
    //span {
    //    background: $color-white;
    //    display: inline-block;
    //    line-height: 1;
    //}
}

h1, .h1, h2, .h2, h3, .h3 {
    font-family: $font01;
}

h4, .h4 {
    font-family: $font03;
    text-transform: uppercase;
    font-size: pxToRem(26);
}

address {
    font-style: normal;
}

.image__description {
    font-family: $font03;
    font-size: pxToRem(18);
    color: #000;
    margin-top: 16px;
    text-transform: uppercase;
}

.dynamoe-container {
    display: inline;
    //padding: 0 15px;
    //background-color: $color-black;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;

    }
    .container--nomobile {
        padding-left: 8px;
        padding-right: 8px;

    }
}

@include media-breakpoint-down(sm) {
    h1, .h1, h2, .h2 {
        font-size: pxToRem(32);
    }
}
.bookingoverlay, .gutscheineoverlay {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(115, 115, 115, .95);

    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    //align-items: flex-end;
    justify-content: center;
    transition: all 1s ease;
}

.bookingmap__container {
    position: relative;
    height: 100%;
    width: 1000px;
    background-image: url("/assets/img/brettermap_2.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.bookingoverlay__content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    padding: $spacing-m 16px;
    background-color: $color-secondary;

    .button:not(:last-child) {
        margin-bottom: 12px;
    }
}

.js-show-bookingoverlay {
    .bookingoverlay {
        opacity: 1;
        pointer-events: auto;
    }
}

.js-show-gutscheineoverlay {
    .gutscheineoverlay {
        opacity: 1;
        pointer-events: auto;
    }
}

.link__buesum {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 50%;
    transform: translateY(-50%);
}

.link__hhf {
    position: absolute;
    top: 50%;
    right: 0;
    width: 50%;
    height: 50%;
    transform: translateY(-50%);
}

@include media-breakpoint-down(xl) {
    .bookingmap__container {
        width: 75%;
    }
}

@include media-breakpoint-down(lg) {
    .bookingmap__container {
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .bookingoverlay__content .button:not(.button--link) {
        margin-right: initial;
    }
}



