
.faq__item .faq__header {
    cursor: pointer;
    position: relative;
    padding-right: 56px;
}

.faq .uk-open .faq__item .faq__header:after {
    transform: translateY(-50%) rotate(45deg);
}

.faq__item .article__content > :last-child {
    margin-bottom: 0;
}

.faq__item .faq__body {
    z-index: 5;
    position: relative;
}

.faq__item .faq__image {
    z-index: 10;
    position: relative;
}

.faq {
    margin: 96px 0;
}

.faq-items__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.faq-item__container {
    &:nth-child(n+1) .faq__header {
        transform: rotate(-3deg);
    }

    &:nth-child(n+2) .faq__header {
        transform: initial;
    }

    &:nth-child(n+3) .faq__header {
        transform: rotate(3deg);
    }

    &.uk-open .faq__item {
        margin-bottom: $spacing-l;
    }
}

.faq__item {
    display: flex;
    flex-direction: column;
    &:hover {
        .faq__header::before {
            right: -16px;
            background-color: $color-primary-hover;
        }
        .faq__header::after {
            right: -4px;
        }
    }
}

.faq-item__container.uk-open .faq__header {
    display: inline-block;
    margin-bottom: 10px;
    &::before {
        right: -16px;
        background-color: $color-primary-hover;
    }
    &::after {
        right: -4px;
    }
}

.faq__header {
    align-self: center;
    transition: margin .25s ease;
    text-align: center;

    &:not(:last-child) {
        margin-bottom: $spacing-s;
    }

    &::before {
        content:'';
        position: absolute;
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        background-color: $color-primary;
        border-radius: 50%;
        transition: .25s ease;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-image: inline-svg('plus-m', $color-white);
        background-size: 24px 24px;
        background-position: center center;
        background-repeat: no-repeat;
        transition: .25s ease;
    }

    h3 {
        display: inline;
        //background-color: $color-white;
        color: #fff;
        background: #000;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        text-transform: uppercase;
        font-size: pxToRem(27);
        display: inline;
        line-height: 1.8;
        padding: 6px 22px 4px 22px;

    }
}

.faq__content {
    padding: $spacing-l;
    width: 100%;
}

.faq__body h4 {
    margin-bottom: $spacing-s;
    text-transform: uppercase;
}

.faq__body ul {
    padding-left: 0 !important;
}

.faq__body ul > li {
    list-style-type: none;
    padding-left: $spacing-s !important;

    &::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 12px;
        height: 12px;
        background-image: inline-svg('angle-s', $color-black, transparent);
        background-size: 12px 12px;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.faq__body + .faq__image {
    margin-top: $spacing-xl;
}

.faq__image {
    display: flex;
    justify-content: center;

    .image:not(:first-child) {
        margin-left: $spacing-s;
    }

    .image {
        overflow: hidden;
        max-width: 600px;
    }

    img {
        transform: scale(1.00);
        transition: transform .4s ease !important;
        &:hover {
            transform: scale(1.04);
            transition: transform .4s ease !important;
        }
    }

    .image:not(.empty):before {
        z-index: 0;
    }
}

@include media-breakpoint-down(sm) {
    .faq {
        margin: $spacing-xl 0;
    }

    .faq__item:hover .faq__header::after {
                right: -4px;
        }

    .faq__header {
        align-self: initial;
    }

    .faq__body + .faq__image {
        margin-top: $spacing-s;
    }

    .faq__image {
        flex-direction: column;
    }

    .faq__image .image:not(:first-child) {
        margin-left: initial;
        margin-top: $spacing-s;
    }
}